.SettingPage .box {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.SettingPage input.disable {
  background-color: #f3f4f6;
  pointer-events: none;
}
