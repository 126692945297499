.LoginPage {
  font-size: 16px;
  margin: 0 auto;
  height: 100vh;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f8fb;
}

.LoginPage_container {
  background-color: #fff;
  padding: 24px;
  border: 0.0625rem solid rgba(46, 54, 80, 0.125);
  border-radius: 0.5rem;
  width: 90%;
  max-width: 550px;
  min-width: 350px;
}

.LoginPage_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.LoginPage img {
  width: 256px;
  height: 256px;
}

.LoginPage_Form {
  margin: 0 auto;
  max-width: 80%;
}

.LoginPage_Form .username,
.LoginPage_Form .password {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}

.LoginPage_Form .password input {
  padding-right: 2.1rem;
}

.LoginPage_Form .password span.showpass_icon {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 8px;
  cursor: pointer;
  min-width: auto;
}

.LoginPage_Form span.text-error.mb-0 {
  margin-bottom: 0;
}

.LoginPage_Form span {
  display: block;
  min-width: 85px;
  font-weight: 700;
  white-space: nowrap;
}

.form_input + .form_input {
  margin-top: 12px;
}

.LoginPage_Form span.text-error {
  color: #cf0b0b;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.LoginPage_Form input {
  outline: none;
  border: 1px solid #000;
  padding: 7px;
  width: calc(100% - 68px);
  border-radius: 2px;
}

.LoginPage_Form .btn_submit {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .LoginPage_Form {
    max-width: 100%;
  }

  .LoginPage_Form .btn_action button {
    width: 100%;
  }
}
