.ContainerPage {
  min-height: 100vh;
  background-color: #ecf0f4;
}

.action_btn.disable .MuiSvgIcon-root {
  opacity: 0.5;
  pointer-events: none;
}

.ContainerPage_content {
  display: flex;
  /* max-width: 1284px; */
  margin: 0 auto;
}

.ContainerPage_content table {
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  margin: 0;
}

.ContainerPage_content table thead tr {
  background-color: #61dafb;
  color: #262b40;
}

.ContainerPage_content table tr th {
  white-space: nowrap;
}

.ContainerPage_content .fc-col-header-cell {
  padding: 8px 0;
}

.ContainerPage_content_outlet {
  padding: 24px;
  padding-bottom: 8px;
  width: calc(100% - 260px);
}

@media screen and (max-width: 767px) {
  .ContainerPage_content {
    flex-direction: column;
    position: relative;
    min-height: 100vh;
  }

  .ContainerPage_content_outlet {
    padding: 16px;
    padding-top: 80px;
    padding-bottom: 8px;
    width: 100%;
  }

  .ContainerPage .Inventory_page .OrderPage_search {
    padding: 0;
  }

  .ContainerPage_content .navbar_menu {
    position: absolute;
    z-index: 9;
    display: none;
    transition: display 1s linear;
  }

  .ContainerPage_content.openMenu .navbar_menu {
    display: block;
    width: 100vw;
    height: 100%;
  }

  .ContainerPage_content.openMenu .navbar_menu ul.navbar_menu_list {
    box-shadow: none;
    width: 100%;
  }

  .ContainerPage_content.openMenu .navbar_menu .close_menu {
    display: block;
  }

  .ContainerPage_content .navbar_menu_mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .openMenu.ContainerPage_content .navbar_menu_mobile {
    z-index: 0;
  }
}

.ContainerPage .fc-scrollgrid-liquid {
  margin: 0;
  min-width: 700px;
}

.ContainerPage .fc-dayGridMonth-view.fc-view.fc-daygrid {
  overflow-x: auto;
}

.ContainerPage .fc .fc-toolbar.fc-header-toolbar {
  margin: 0;
  margin-bottom: 8px;
}

.ContainerPage .fc .fc-scrollgrid-section-body table {
  margin: 0;
  max-height: 700px;
}

.ContainerPage .fc .fc-scroller-liquid-absolute,
.ContainerPage .fc .fc-scroller {
  overflow: hidden !important;
}

.ContainerPage .fc .fc-button-group {
  gap: 16px;
}

.ContainerPage .fc-theme-standard td {
  height: 100px;
}

.ContainerPage .fc-theme-standard .fc-popover {
  max-width: 500px !important;
}

.ContainerPage .fc-event-title {
  text-overflow: ellipsis;
}

@media screen and (max-width: 1280px) {
  .ContainerPage .fc-theme-standard .fc-popover {
    max-width: 350px !important;
  }
}

@media screen and (max-width: 767px) {
  .ContainerPage .fc .fc-toolbar-title {
    font-size: 1.25em;
  }

  .ContainerPage .fc .fc-button-group {
    gap: 8px;
  }

  .ContainerPage .fc .fc-toolbar.fc-header-toolbar {
    gap: 10px;
    flex-wrap: wrap;
  }
}
