* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.container_global {
  overflow: hidden;
  position: relative;
  color: #262b40;
}

.container_global .text-align {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container_global .text-align input {
  width: 16px;
  height: 16px;
}

.container_global .text-align.checkbox {
  min-height: 42px;
}

.container_global input {
  outline: none;
  padding: 0.3rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
}

.container_global input::placeholder {
  color: #afb9cd;
}

.container_global select {
  outline: none;
  width: 120px;
  min-width: fit-content;
  padding: 0.3rem 1.75rem 0.3rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-image: url('../Assets/Images/arrow_down.svg');
  color: #66799e;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  -webkit-appearance: none;
  appearance: none;
}

.container_global ul {
  list-style-type: none;
  display: flex;
}

.container_global .btn_action button {
  font-weight: 600;
  color: #fff;
  background-color: #262b40;
}

.container_global .btn_action button:hover {
  opacity: 0.8;
  background-color: #262b40;
}

.container_global .btn_action button.MuiButton-containedError {
  background-color: #d32f2f;
}

.container_global .btn_action button.MuiButton-containedError:hover {
  background-color: #d32f2f;
}

.container_global .btn_action button span {
  min-width: auto;
}

.container_global .btn_action button.disable {
  opacity: 0.5;
  cursor: default;
}

.container_global .OrderPage_table table td {
  white-space: nowrap;
}

.container_global .OrderPage_table table td.number {
  text-align: right;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
input:-internal-autofill-previewed {
  -webkit-text-fill-color: #262b40;
  background-color: white !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
