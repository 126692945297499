.Orderpage_info .info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}
.OrderPage_searchs {
  display: flex;
  justify-content: flex-end;
}
.input-invoice{
  margin-left: 10px;
}

.OrderPage_searchs {
 margin-top: -20px;
}

.OrderPage_search_boxs {
  display: flex;
  align-items: center;
}

.OrderPage_search_boxs > span {
  margin-right: 10px;
}

.search-input-container {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 30px;
}

.search-input-container .button-search {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.search-input-container .button-search svg {
  width: 20px;
  height: 20px;
}

.search-input-container .debounce-input {
  padding-left: 40px; 
  padding-right: 10px;
  height: 35px;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}


.fulfilment_page .Orderpage_info {
  display: flex;
  align-items: center;
  gap: 100px;
}

.fulfilment_page .select_dropdown {
  width: 180px;
}

.OrderPage_table .row_data td:first-child {
  position: relative;
  min-width: 50px;
}

.OrderPage_table .row_data .input_checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fulfilment_page .select_search {
  color: #262b40;
  background-color: #fff;
}

.fulfilment_page .Orderpage_info .info span {
  display: block;
  min-width: 115px;
}

.fulfilment_page .input_date {
  position: relative;
  width: 180px;
}

.fulfilment_page #stock_print {
  display: none;
}

.fulfilment_page .input_date input {
  width: 150px;
}

.fulfilment_page .action-refunds {
  display: flex;
  width: 100%;
  gap: 8px;
}

.fulfilment_page .input_date input[type='date'] {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 150px;
  left: 0;
}

.fulfilment_page #content {
  display: none;
}


.fulfilment_page .icon-nostock {
  max-height: 24px;
}

.container_global .OrderPage_btn {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: center;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 32px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.fulfilment_page .MuiDataGrid-columnHeader:nth-child(10) .MuiDataGrid-columnHeaderTitleContainer {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1023px) {
  .fulfilment_page .Orderpage_info {
    gap: 0;
    align-items: flex-start;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .fulfilment_page .Orderpage_info .info span {
    display: block;
    min-width: 115px;
  }
}

.Orderpage_info .list_item.show {
  min-width: 180px;
}
