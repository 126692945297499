.navbar_menu_mobile {
  background-color: #262b40;
  padding: 10px 16px;
  display: none;
  position: fixed;
  z-index: 1;
  width: 100%;
}

.navbar_menu_mobile .navbar_user_logo {
  justify-content: flex-start;
  margin: 0;
}

.navbar_menu_mobile .navbar_user {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 24px;
  justify-content: space-between;
}

.navbar_menu_mobile .navbar_user_logo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.navbar_menu_mobile .menu_mobile {
  display: block;
}
