.Orderpage_info .info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.Stocklist_page #label_stock {
  display: none;
}

.Stocklist_page .Orderpage_info .delivery_date input {
  height: 25px;
}

.pricing-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.container_global .OrderPage_btn {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 32px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
