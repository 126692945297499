.HomePage .react-calendar {
  min-width: fit-content;
}

.HomePage .OrderPage_table .row_data {
  position: relative;
}

.HomePage .OrderPage_table .row_data .checkbox {
  position: relative;
}

.HomePage .OrderPage_table .row_data .container_row {
  position: absolute;
  left: 50%;
  padding: 8px 16px;
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  background: #fff;
}

.HomePage .OrderPage_table .row_data .container_row div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.OrderPage_table .row_data .action {
  width: 35px;
  position: relative;
  cursor: pointer;
}

.OrderPage_table .row_data .action_box_menu {
  position: absolute;
  width: 100px;
  left: 0;
}

.OrderPage_table .row_data .action_btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.OrderPage_table .label_user {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.react-calendar__navigation__label {
  pointer-events: none;
}

.OrderPage_table thead th:last-child {
  width: 80px;
}

.HomePage .react-calendar .react-calendar__navigation {
  margin-bottom: 0;
  border-bottom: 1px solid #dddddd;
}

.react-calendar__navigation button {
  font-size: 1.5rem;
}

.react-calendar__tile--active {
  background-color: #61dafb;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #61dafb;
}

.HomePage .react-calendar abbr {
  text-decoration: none;
}

.HomePage .react-calendar__navigation button {
  font-size: 16px;
  font-weight: 700;
}

.react-calendar .react-calendar__month-view__days button {
  height: 60px;
  width: 125px;
  border: 1px solid #dddddd;
  border-left: 0;
}

.react-calendar .react-calendar__month-view__days button:nth-child(odd) {
  border-bottom: 0;
}

.react-calendar .react-calendar__month-view__days button:nth-child(even) {
  border-bottom: 0;
}

.react-calendar .react-calendar__month-view__days button:nth-child(1) {
  border-bottom: 0 !important;
}

.addUser_form {
  margin-top: 24px;
}

.addUser_form p {
  font-weight: 600;
  line-height: 27px;
  white-space: nowrap;
}

.addUser_form_input {
  display: flex;
}

/* .btn_addUser button {
    font-size: 16px;
    outline: none;
    border-radius: 4px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 6px 8px;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    background-color: #262b40;
} */

.addUser_form .input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 16px;
  margin-right: 24px;
}

.addUser_form .input input {
  border: 1px solid #000;
  padding: 5px;
}

.addUser_form .btn_addUser {
  display: flex;
  align-items: flex-end;
}

.HomePage .btn_action.OrderPage_btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.OrderPage_table .MuiSvgIcon-root {
  cursor: pointer;
}

@media screen and (max-width: 430px) {
  .fc-button-group {
    font-size: 12px;
  }
}
