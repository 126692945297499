.Inventory_page .OrderPage_search {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.Inventory_page .OrderPage_search .number_tank {
  text-align: center;
  font-weight: 600;
}

.OrderPage_search_box {
  flex: 1;
  max-width: 300px;
  display: flex;
  align-items: center;
  padding: 4px;
  overflow: hidden;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
  background-color: #fff;
}

.Inventory_page .select-fish .MuiOutlinedInput-root .MuiAutocomplete-input {
  border: none;
  border-radius: 0;
  background-color: transparent;
  padding-left: 10px;
}

.Inventory_page .select-fish .MuiInputBase-root {
  height: 42px;
  padding-right: 32px !important;
  padding: 0;
}

.Inventory_page .select-fish .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0000003b;
}

.Inventory_page .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 6px;
}

.OrderPage_search input {
  flex: 1;
  border: none;
  height: 100%;
  width: 100%;
}

.OrderPage_search_box div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin-right: 5px;
  cursor: pointer;
}

.Orderpage_info .info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.Inventory_page .OrderPage_search_box input {
  border: none;
}

.Orderpage_info .info span {
  min-width: 115px;
  display: block;
}

.OrderPage_btn {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: center;
}

.Inventory_page .table-title {
  margin-bottom: -16px;
}

.box_quantity_inventory input {
  width: 100%;
  text-align: right;
  border-radius: 0;
}

.text_overflow {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Inventory_page .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
  background-color: transparent;
}

.Inventory_page .MuiDataGrid-root .MuiDataGrid-row.row-select {
  background-color: rgba(25, 118, 210, 0.1);
}

.Inventory_page .table_outStock .MuiDataGrid-columnHeader:nth-child(3) .MuiDataGrid-columnHeaderTitleContainer {
  display: flex;
  justify-content: center;
}

.Inventory_page .data-grid {
  overflow-y: visible;
  overflow-x: scroll;
  font-size: 14px;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fff;
}

.Inventory_page .data-grid .data-grid-header {
  display: flex;
  background-color: #61dafb;
  color: #262b40;
  height: 56px;
  min-width: fit-content;
}

.MuiDataGrid-main .MuiDataGrid-row {
  min-height: 43px !important;
}

.data-grid .data-grid-header .data-grid-header-item {
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  position: relative;
}

.Inventory_page .MuiDataGrid-row .MuiDataGrid-cell:last-child {
  position: relative;
}

.Inventory_page .checkbox-receiables label {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.data-grid .data-grid-body-row,
.data-grid .row-item {
  display: flex;
  align-items: center;
}

.data-grid .data-grid-body-row {
  border-bottom: 1px solid rgb(224, 224, 224);
}

.data-grid .row-item {
  padding: 5px 10px;
}

.data-grid .data-grid-header .data-grid-header-item.text-right,
.data-grid .row-item.text-right {
  justify-content: flex-end;
}

.data-grid .data-grid-header .data-grid-header-item:nth-child(1),
.data-grid .data-grid-body-row .row-item:nth-child(1) {
  min-width: 80px;
  flex: 1;
}

.data-grid .data-grid-header .data-grid-header-item::after {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 14px;
  background-color: rgb(224, 224, 224);
}

.data-grid .data-grid-header .data-grid-header-item:nth-child(1):after {
  display: none;
}

.data-grid .data-grid-header .data-grid-header-item:nth-child(2),
.data-grid .data-grid-body-row .row-item:nth-child(2) {
  min-width: 250px;
  flex: 2.5;
}

.data-grid .data-grid-header .data-grid-header-item:nth-child(3),
.data-grid .data-grid-body-row .row-item:nth-child(3) {
  min-width: 100px;
  flex: 1;
}

.data-grid .data-grid-header .data-grid-header-item:nth-child(4),
.data-grid .data-grid-body-row .row-item:nth-child(4) {
  min-width: 170px;
  flex: 1;
}

.data-grid .data-grid-header .data-grid-header-item:nth-child(5),
.data-grid .data-grid-body-row .row-item:nth-child(5) {
  min-width: 120px;
  flex: 1;
}

.OrderPage_table table tbody tr {
  height: 60px;
}

.Inventory_page .select_dropdown .list_item {
  min-width: 100%;
  left: 0;
}

.OrderPage_inputfish {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.button_add-fish {
  min-width: 80px;
  border-radius: 5px;
  border: none;
  background-color: #262b40;
  color: #fff;
  font-weight: 600;
}
.add-fish-wrapper .label {
  line-height: 42px;
  font-weight: bold;
}
.button_add-fish:hover {
  background-color: #61dafb;
}

.button_add-fish:hover {
  color: #262b40;
}

.add-fish-wrapper {
  display: flex;
  justify-content: start;
  gap: 24px;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px;
  background: #fff;
}

.add-fish-wrapper .select-fish {
  min-width: 400px;
  background-color: #fff;
}
.input-tank-no {
  min-width: 120px;
}