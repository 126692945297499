.loading_modal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(0, 0, 0, 0.5); */
}