.select_dropdown {
  position: relative;
  min-width: 180px;
  background-color: #fff;
  width: 100%;
  font-size: 1rem;
  height: 36px;
  font-weight: 400;
  line-height: 1.5;
  border: 0.0625rem solid #d1d7e0;
  color: #66799e;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  border-radius: 0.5rem;
}

.disabled .select_dropdown .text_show {
  background-color: #f3f4f6;
}

.select_dropdown .text_show {
  position: absolute;
  background-image: url('../../Assets/Images/arrow_down.svg');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.3rem 1.75rem 0.3rem 0.75rem;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
}

.select_dropdown .list_item {
  position: absolute;
  display: none;
  width: 100%;
  left: -2px;
  top: 36px;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  border: 0.0625rem solid #d1d7e0;
  z-index: 99;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
  min-width: 312px;
  border-radius: 0.5rem;
  scrollbar-width: none;
}

.select_dropdown .list_item::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.select_dropdown .list_item.show {
  display: block;
}

.select_dropdown .list_item li {
  padding: 4px 8px;
  cursor: pointer;
}

.select_dropdown .list_item li:hover {
  background-color: #efefef;
}
