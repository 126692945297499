.stock_label {
  display: flex;
  /* flex-direction: column;
  width: 378px; */
  height: 94px;
  /* page-break-after: always; */
}
.stock_label .top,
.stock_label .bottom {
  display: flex;
}

.stock_label .item {
  flex: 1;
  flex-direction: column;
}

.stock_label p {
  margin-bottom: 8px;
  /* font-size: 32px; */
  /* min-height: 37px; */
}

.stock_label .d-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.stock_label .top div,
.stock_label .bottom div,
.stock_label .center {
  flex: 1;
  text-align: center;
  /* padding: 24px; */
  /* height: 95px; */
}

.stock_label .center {
  flex-direction: column;
  border-right: 2px solid #000;
  border-left: 2px solid #000;
  text-align: center;
  height: auto;
  /* min-height: 260px; */
}

.stock_label .top div:nth-child(1),
.stock_label .bottom div:nth-child(1) {
  border-bottom: 1px solid #000;
  /* min-height: 265px; */
}

.stock_label .top div:nth-child(2),
.stock_label .bottom div:nth-child(2) {
  border-top: 1px solid #000;
  /* min-height: 265px; */
}
