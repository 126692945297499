.OrderPage_search {
  display: flex;
  justify-content: flex-end;
}

.OrderPage_search_box {
  /* border: 1px solid #000; */
  border-radius: 50px;
  width: 300px;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 4px;
}




.AccountPage .select_dropdown {
  min-width: 220px;
  height: 40px;
}

.AccountPage .select_dropdown .list_item li {
  padding: 6px 10px;
}

.AccountPage .select_dropdown .list_item {
  top: 40px;
}

.container_global .text-align.checkbox-receiables {
  position: relative;
}

.OrderPage_search input {
  flex: 1;
  border: none;
  height: 100%;
  width: 100%;
}

.OrderPage_search_box div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin-right: 5px;
  cursor: pointer;
}

.Orderpage_info .info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.OrderPage_table.payable .filter_action {
  margin-bottom: 24px;
}

.Orderpage_info .info span {
  min-width: 115px;
  display: block;
}

.AccountPage .OrderPage_btn.btn_action {
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
}

.filter_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.AccountPage .OrderPage_search_box input {
  border: none;
}

.AccountPage .OrderPage_search {
  margin-bottom: 0;
}

.AccountPage .filter_action .info {
  margin-bottom: 0;
}

.AccountPage .total_box {
  display: flex;
  align-items: center;
  gap: 8px;
}

.AccountPage .total_box div {
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4px;
  font-weight: 600;
}

.AccountPage .table-title {
  margin-bottom: 16px;
}

.AccountPage .dropdown {
  width: 175px;
}

.AccountPage #receivables {
  display: none;
}

.AccountPage #payable {
  display: none;
}

.AccountPage .total_table {
  margin: 10px 0px 16px -12px;
  display: flex;
  flex-wrap: wrap;
}

.AccountPage .OrderPage_search_box {
  width: 300px;
  height: 36px;
}

.AccountPage .total_table .item {
  padding: 8px 12px;
  flex-basis: 25%;
}

.AccountPage .total_table .item .content {
  text-align: center;
  border-radius: 0.5rem;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  color: #fff;
  min-width: 200px;
  background-color: #1abc9c;
}

.AccountPage .total_table .item .content .content_info {
  flex: 1;
}

.AccountPage .paid {
  position: relative;
}

.AccountPage .overflow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.AccountPage .MuiDataGrid-row .MuiDataGrid-cell:last-child {
  position: relative;
}

.AccountPage .checkbox-receiables label {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.AccountPage .action_btn {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AccountPage .action_btn.disable .MuiSvgIcon-root {
  opacity: 0.5;
  pointer-events: none;
}

.AccountPage .MuiDataGrid-columnHeader:nth-child(7) .MuiDataGrid-columnHeaderTitleContainer {
  width: 100px;
  display: flex;
  justify-content: center;
}

.AccountPage .total_table .item .content h3 {
  line-break: anywhere;
  font-size: 32px;
  margin-top: 4px;
}

.AccountPage .total_table .item:nth-child(2) .content {
  background-color: #4a69bd;
}

.AccountPage .total_table .item:nth-child(3) .content {
  background-color: #9b59b6;
}

.AccountPage .total_table .item:nth-child(4) .content {
  background-color: #f97f51;
}

.AccountPage .total_table .item:nth-child(5) .content {
  background-color: #eb4d4b;
}

.AccountPage .total_table .item:nth-child(6) .content {
  background-color: #22a6b3;
}

.AccountPage .total_table .item:nth-child(7) .content {
  background-color: #007bff;
}

.AccountPage .total_table .item .content img {
  width: 48px;
  height: 48px;
}

.AccountPage .total_table .item .content .AccountPage .total_table .item p {
  margin-bottom: 8px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 32px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

@media screen and (min-width: 767px) and (max-width: 1280px) {
  .AccountPage .total_table .item .content h3 {
    line-break: anywhere;
    font-size: 28px;
  }

  .AccountPage .total_table .item {
    padding: 8px 12px;
    flex-basis: 25%;
  }

  .AccountPage .total_table .item .content {
    min-height: 150px;
    min-width: 235px;
  }
}

@media screen and (max-width: 767px) {
  .AccountPage .total_table .item {
    padding-right: 0;
  }

  .AccountPage .total_table {
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
  }

  .AccountPage .account-bottom {
    flex-direction: column-reverse;
  }
}
