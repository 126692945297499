.navbar_menu {
  background-color: #262b40;
  min-height: 100vh;
}

.navbar_menu .navbar_user_logo {
  position: relative;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.navbar_menu .navbar_user_info {
  margin-top: 8px;
  color: #eaedf2;
  font-weight: 700;
  text-align: center;
}

.navbar_menu .navbar_user_logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.navbar_menu .close_menu {
  display: none;
  position: absolute;
  cursor: pointer;
  right: 16px;
}

.navbar_menu ul.navbar_menu_list {
  list-style-type: none;
  display: block;
  margin-bottom: 32px;
  width: 260px;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 16px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  height: 100%;
}

.navbar_menu li {
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 4px;
  box-sizing: border-box;
}

.navbar_menu li:hover {
  color: #eaedf2;
  background-color: #2e3650;
}

.navbar_menu li a {
  text-decoration: none;
  color: #eaedf2;
  display: block;
  padding: 0.55rem 0.75rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.navbar_menu li a.active {
  color: #262b40;
  background-color: #61dafb;
  border: 0.0625rem solid #61dafb;
  box-sizing: border-box;
}
