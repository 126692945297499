.modal-order .label-company,
.modal-order .label-delivery-date {
  flex: 1;
}

.modal-order .select_dropdown {
  min-width: 282px;
  height: 42px;
}

.label-order {
  display: flex;
  gap: 10px;

}

.duplicateFish .MuiAutocomplete-clearIndicator {
  display: none;
}

.label-order .label-marking {
  margin-left: 30px;
}

.label-order .label-quantity {
  margin-left: 45px;
}

.input-marking {
  width: 20px;
}


.label-order .label-price {
  margin-left: 75px;
}

.label-order .label-size {
  margin-left: 115px;
}

.label-company .select_dropdown .list_item.show {
  min-width: 100px;
  max-width: 200px;
  left: 0;
}

.edit_modal.edit_modal_order .select_dropdown .list_item.show {
  max-width: none;
}

.input-size {
  width: 10px;
}

/* 
.label-order .label-marking {
  flex: 2;
} */

.label-order .label-description {
  display: flex;
  align-items: center;
  gap: 12px;

}

/* .label-description .label-quantity {
  flex: 1.5;
  display: flex;
  align-items: center;
}

.label-description .label-price {
  flex: 1.5;
  display: flex;
  align-items: center;
} */

.label.orderList {
  max-height: 350px;
  overflow: auto;
  scrollbar-width: none;
}

.label.label.orderList::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

.input-quantity.no-quantity {
  border-color: red;
}

.input-price.no-price {
  border-color: red;
}

.marking-box-mobile {
  display: none;
}

.label-remark {
  width: 100%;
}

@media screen and (max-width: 867px) {
  .modal-order.MuiBox-root {
    padding: 0;
  }

  .edit_modal .label .delivery_date input[type='text'] {}

  .edit_modal .label .delivery_date input[type='date'] {
    padding-right: 0;
    padding-left: 0;
  }

  /* .label-order .label-marking,
  .label-order .label-quantity,
  .label-order .label-price {
    display: none;
  } */
}

@media screen and (max-width: 580px) {
  .modal-order #order .MuiOutlinedInput-root {
    padding-right: 36px;
  }

  .modal-order .label-company {
    flex: 3.5;
  }

  .modal-order .label-delivery-date {
    flex: 3;
  }

  .addOrder_form_input>.MuiBox-root {
    gap: 8px;
  }

  .modal-order .modal-order-container {
    gap: 8px;
  }

  .modal-order input.input-quantity {
    height: 38px;
  }

  .modal-order input.input-price {
    height: 38px;
  }

  .modal-order .modal-order-container div:first-child {
    flex: 2.5;
  }
}