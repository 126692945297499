.Fish_page .OrderPage_search {
  justify-content: space-between;
  margin: 0;
  flex-wrap: wrap;
  gap: 16px;
}

.Fish_page .OrderPage_search_box input {
  border: none;
}

.Fish_page .OrderPage_table .row_data {
  position: relative;
}

.Fish_page .pricing-table {
  justify-content: space-between;
  padding-left: 32px;
  gap: 16px;
}

.Fish_page .btn_action.OrderPage_btn {
  justify-content: flex-start;
}

.Fish_page .OrderPage_table .row_data .checkbox {
  width: 35px;
}

@media screen and (max-width: 1280px) {
  .Fish_page .pricing-table {
    justify-content: space-between;
    padding-left: 0;
    gap: 16px;
  }
}

@media screen and (max-width: 767px) {
  .Fish_page .OrderPage_search {
    flex-direction: column-reverse;
  }
}
