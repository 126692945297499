.calendar_modal.edit_modal textarea {
  max-height: 80px;
  min-height: auto;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__month-container {
  float: left;
}

.calendar_modal textarea::placeholder,
.calendar_modal input::placeholder {
  color: #66799e;
}
