.customerPage .info_user {
  margin: 0 0 24px;
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
}

.customerPage .OrderPage_table .row_data {
  position: relative;
}
.ContainerPage_content_outlet .customerPage .OrderPage_table .row_data td:first-child {
  position: relative;
}

.customerPage .input_info {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.customerPage .input_info.btn_action {
  align-items: flex-end;
}

.customerPage .input_info span {
  min-width: 115px;
}

.customerPage .input_info input {
  padding: 8px;
}

.Orderpage_info .info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.customerPage .Orderpage_info .delivery_date input {
  height: 25px;
  width: 120px;
}

.container_global .OrderPage_btn {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: center;
}

.customerPage .select_dropdown {
  width: 120px;
}

.container_global .customerPage .btn_action {
  justify-content: flex-start;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 32px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

@media screen and (max-width: 767px) {
  .customerPage .input_info input {
    width: 100%;
  }
}

.customerPage .list_item.show {
  min-width: 180px;
}
