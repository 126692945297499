.edit_modal.MuiBox-root {
  padding-top: 16px;
}

.edit_modal_order.disable .modal-common .select_dropdown .text_show,
.edit_modal_order.disable #combo-box-demo,
.edit_modal_order.disable .MuiAutocomplete-root,
.edit_modal_order.disable .marking-box input,
.edit_modal_order.disable .delivery_date input,
.edit_modal_order.disable .label-remark input {
  background-color: #f3f4f6;
  pointer-events: none;
}

/* .edit_modal_order.disable-fish .MuiAutocomplete-root.disable,
.edit_modal_order.disable-fish .MuiAutocomplete-root.disable input {
  background-color: #f3f4f6;
  pointer-events: none;
} */

.edit_modal_order.disable.disable-full .modal-common input {
  background-color: #f3f4f6;
  pointer-events: none;
}

.edit_modal_order.disable .button-action,
.edit_modal_order.disable .delivery_date {
  pointer-events: none;
}

.edit_modal_order.disable .marking-box input.input-note-refund {
  background-color: #fff;
  pointer-events: all;
}


.edit_modal .addUser_form_input p {
  font-weight: 600;
  line-height: 27px;
  white-space: nowrap;
}



.edit_modal.edit_modal_order {
  min-width: 350px;
  max-width: 780px;
}

.edit_modal.edit_modal_order .quantity-box {
  flex: 1.5;
}

.edit_modal.edit_modal_order .price-box {
  flex: 1.5;
}

.edit_modal.edit_modal_order .marking-box {
  flex: 2;
}

.edit_modal p {
  color: #262b40;
}

.edit_modal h4 {
  width: 100%;
}

.edit_modal .paid input {
  height: 20px;
  width: 20px;
}

.edit_modal input.disable {
  background-color: #d1d7e0;
  pointer-events: none;
}

.edit_modal input::placeholder {
  color: #66799e;
}

.edit_modal .addUser_form_input {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 11px;
  gap: 16px;
}

.edit_modal .addOrder_form_input {
  padding: 16px 0;
}

.edit_modal.edit_modal_order .addOrder_form_input {
  padding-bottom: 0;
}

.edit_modal .addUser_form_input .input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}

.edit_modal .info_user {
  margin: 24px 0;
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
}

.edit_modal .input_info {
  margin-bottom: 8px;
}

.edit_modal .input_info.btn_action {
  align-items: flex-end;
}

.edit_modal .input_info span {
  min-width: 115px;
  display: inline-block;
  font-weight: 600;
  line-height: 27px;
}

.Orderpage_info .info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.edit_modal .Orderpage_info .delivery_date input {
  height: 25px;
  width: 120px;
}





.edit_modal input {
  width: 100%;
  outline: none;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
}

.edit_modal .MuiAutocomplete-root .MuiInputBase-root {
  height: 42px;
  padding: 0;
}

.edit_modal .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  border-radius: 0;
  border: 0;
  padding-left: 12px;
}

.edit_modal .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #d1d7e0;
  border-width: 1px;
}

.edit_modal select {
  outline: none;
  width: 100%;
  min-width: fit-content;
  padding: 0.4rem 1.75rem 0.4rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-image: url('../../Assets/Images/arrow_down.svg');
  color: #66799e;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  appearance: none;
}

.edit_modal span.mess-error {
  color: red;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 400;
}

.edit_modal .addOrder_form_input .label {
  margin-bottom: 16px;
}

.edit_modal .label .delivery_date {
  position: relative;
}

.edit_modal button.update_btn {
  background-color: #61dafb;
}

.edit_modal textarea {
  outline: none;
  font-size: 1rem;
  resize: none;
  padding: 8px 12px;
  color: #66799e;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  min-height: 80px;
  width: 100%;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
}

.edit_modal .label .delivery_date input[type='text'] {
  position: absolute;
  right: 0;
  top: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.edit_modal .label .delivery_date .calendar_icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.edit_modal .label .delivery_date input[type='date'] {
  opacity: 0;
  cursor: pointer;
}

.edit_modal .addOrder_form_input .label p {
  margin-bottom: 6px;
  font-weight: 600;
  line-height: 27px;
}

.edit_modal .label p span,
.edit_modal .input_info span span {
  color: red;
}

.edit_modal:focus-visible {
  outline: none;
}

.edit_modal .user_password_edit {
  position: relative;
}

.edit_modal .user_password_edit .disable {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  background-color: #ddd;
}

.edit_modal ul {
  list-style-type: none;
}

.edit_modal .select_dropdown p.text_overflow {
  color: #66799e;
  margin: 0;
  font-weight: 400;
}