.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-cell {
  padding: 8px 10px;
  width: 100%;
}

.MuiDataGrid-root .MuiDataGrid-cell p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 24px;
  line-height: 24px;
}

.MuiBox-root .MuiDataGrid-root .MuiDataGrid-columnHeader {
  pointer-events: none;
}

.MuiBox-root .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: 600;
}

.MuiBox-root .MuiDataGrid-root .MuiDataGrid-columnHeader:first-child {
  pointer-events: all;
  outline: none;
}

.MuiBox-root .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiBox-root .MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiBox-root .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none;
}

.MuiBox-root .MuiDataGrid-root .MuiDataGrid-cell > div {
  width: 100%;
}

.MuiDataGrid-root .MuiDataGrid-columnHeadersInner {
  background-color: #61dafb;
}

.MuiBox-root .MuiDataGrid-root {
  color: #262b40;
}

.MuiBox-root .action_btn {
  width: 100%;
  display: flex;
  gap: 8px;
}

.MuiDataGrid-root.table_data .MuiDataGrid-footerContainer {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-selectedRowCount {
  width: auto;
  visibility: visible;
}

.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-overlay {
  position: relative;
}

.loading.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-overlay {
  opacity: 1;
}

.loading.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-overlay::after {
  position: absolute;
  content: 'Loading...';
  opacity: 1;
  z-index: 9;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.Inventory_page .MuiDataGrid-root .MuiDataGrid-main {
  overflow: visible;
  overflow-x: auto;
}

.Inventory_page .MuiBox-root .MuiDataGrid-main .MuiDataGrid-virtualScroller {
  overflow: visible !important;
  width: 100%;
  height: 100%;
}

.Inventory_page .MuiDataGrid-columnHeaders {
  overflow: initial;
}

.MuiDataGrid-main .MuiDataGrid-iconButtonContainer {
  display: none;
}

.MuiDataGrid-main .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator {
  display: none;
}

@media screen and (min-width: 1440px) {
  .Inventory_page .MuiDataGrid-root .MuiDataGrid-main {
    overflow-x: hidden;
  }
}
