.invoice {
  /* padding: 0px 24px 8px; */
  display: flex;
  flex-direction: column;
}

.invoice .invoice_header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
}

.invoice .invoice_header .logo {
  width: 160px;
  height: 145px;
}

.invoice .invoice_header .logo img {
  width: 100%;
  height: 100%;
}

.invoice .invoice_header .infor {
  font-size: 14px;
}

.invoice .invoice_header .invoice_no {
  display: flex;
  gap: 32px;
}

.invoice .invoice_header .infor p,
.invoice .invoice_header .infor h1 {
  margin-bottom: 0px;
  line-height: 24px;
}

.invoice .invoice_header .infor h1 {
  line-height: 38px;
}

.invoice h2 {
  font-weight: 500;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 8px;
}

.invoice .invoice_company {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.invoice .invoice_company:last-child {
  margin-bottom: 8px;
}

.invoice .invoice_company p {
  display: flex;
  gap: 16px;
  min-width: 180px;
  margin-bottom: 2px;
  line-height: 22px;
}

.invoice .invoice_company p span {
  display: flex;
  min-width: 80px;
}

.invoice .invoice_company p span:last-child {
  flex: 1;
  position: relative;
}

.invoice .invoice_company p:first-child {
  flex: 2;
}

.invoice .invoice_company p:last-child span:last-child {
  text-align: center;
  display: inline-block;
}

.invoice .payment_details {
  display: flex;
  justify-content: space-between;
}

.invoice .payment_details .text {
  margin-top: 8px;
}

.invoice .payment_details .text .remark {
  min-height: 42px;
  margin-bottom: 8px;
}

.invoice .payment_details .text .remark h3 {
  margin-bottom: 8px;
}

.invoice .payment_details .text h3 {
  text-decoration: underline;
}

.invoice .payment_details .text > h3 {
  line-height: 30px;
}

.invoice .payment_details .text > p {
  /* margin-top: 12px; */
  line-height: 28px;
}

.invoice .payment_details .total div {
  display: flex;
  justify-content: space-between;
}

.invoice .payment_details .total div p,
.invoice .payment_details .total div div {
  width: 121px;
  /* height: 35px; */
  padding: 4px 8px;
}

.invoice .payment_details .total .subTotal_left {
  width: 150px;
}

.invoice .payment_details .total div div {
  display: flex;
  justify-content: space-between;
  border: 1px solid #00000080;
  border-top: 0;
  background-color: #fff;
}

.invoice .payment_details .total .amount p {
  font-weight: 600;
}

.invoice .signature {
  display: flex;
  justify-content: space-between;
  gap: 200px;
}

.invoice .signature div {
  text-align: center;
  flex: 1;
  padding: 4px 8px;
  border-top: 1px solid #00000080;
}

.invoice .signature .customer {
  margin-top: 80px;
}

.invoice footer {
  opacity: 0;
}

.invoice .invoice-content:last-child footer {
  opacity: 1;
}

.invoice .invoice-content {
  display: flex;
  flex-direction: column;
  height: 1114px;
}

/* .invoice-content + .invoice-content {
  margin-top: 52px;
}

@supports (-moz-appearance: none) {
  .invoice-content + .invoice-content {
    margin-top: 58px;
  }
} */

.invoice .signature .author {
  padding-top: 0;
  border: none;
}

.invoice .count-page {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 18px;
}

.invoice .signature .author p {
  padding-top: 4px;
  border-top: 1px solid #00000080;
}

/* css table */

.invoice .invoice_table-header {
  display: flex;
  justify-content: space-between;
  border: 1px solid #00000080;
  border-bottom: none;
}

.invoice .invoice_table {
  margin-top: 6px;
}

.invoice .invoice_table-header div {
  flex: 1;
  padding: 5px 8px;
  background-color: #1976d21f;
  border-right: 1px solid #00000080;
  min-width: 121px;
}

.invoice .invoice_table-header div:last-child {
  border-right: none;
}

.invoice_table-body {
  width: 100%;
}

.invoice_table-body .cell-data {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #00000080;
  background-color: #fff;
}

.invoice_table-body .cell-data + .invoice_table-body .cell-data {
  border-top: none;
}

.invoice_table-body .cell-data:nth-child(even) {
  background-color: #1976d20d;
}

.invoice_table-body:last-child .cell-data:last-child {
  border-bottom: 1px solid #00000080;
}

.invoice_table-body .cell-data div {
  flex: 1;
  padding: 3px 8px;
  border: 1px solid #00000080;
  border-right: none;
  border-bottom: none;
  min-width: 121px;
  min-height: 26px;
  display: flex;
  align-items: center;
}

.invoice_table-body .cell-data .row_price {
  justify-content: space-between;
}

.invoice_table-body .cell-data .row_quantity {
  justify-content: flex-end;
}

.invoice_table-body .cell-data div:first-child,
.invoice .invoice_table-header div:first-child {
  flex: 2;
  max-width: none;
}

.invoice_table-body .cell-data div:nth-child(2),
.invoice .invoice_table-header div:nth-child(2) {
  flex: 3;
  max-width: none;
}
