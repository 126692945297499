.invoice .payment_details .totalamount {
  min-width: 140px;
}

.invoice .invoice_table .invoice_print {
  max-width: 100px;
}

.invoice-account.invoice {
  padding: 0;
}

.invoice.invoice-account .invoice_header {
  margin-bottom: 32px;
}

.invoice.invoice-account h2 {
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 700;
  margin-bottom: -36px;
  margin-top: 32px;
}

.invoice-account.invoice .invoice_table td,
.invoice-account.invoice .invoice_table th {
  border: 0;
}

.invoice .invoice_table div.account-amount {
  justify-content: flex-start;
  gap: 8px;
}

.invoice-account.invoice .invoice_table tr td:last-child,
.invoice-account.invoice .invoice_table tr th:last-child {
  border-right: 0;
}

.invoice-account.invoice .invoice_table tbody tr:last-child td:not(:first-child) {
  border-top: 1px solid #00000080;
  border-bottom: 1px solid #00000080;
}

.invoice-account.invoice .signature {
  margin-top: 60px;
}

.invoice-account.invoice .invoice_table tbody+tbody tr:first-child td {
  padding-top: 20px;
}

.invoice-account.invoice .signature div {
  text-align: left;
}

.invoice-account.invoice .signature .author p {
  border: 0;
  border-top: 1px solid #00000080;
  font-size: 14px;
  max-width: fit-content;
}

.invoice-account.invoice .signature .author p.verify {
  margin-top: 16px;
  border-top: 0;
}

.invoice-account .payment_sum {
  display: flex;
  margin-left: 140px;
  margin-top: 20px;
  justify-content: space-between;
}

.invoice-account .date-invoice span {
  font-weight: 600;
}

.invoice-account .value-invoice {
  display: flex;
  gap: 48px;
}

.invoice-account .value-invoice p {
  display: flex;
  width: 112px;
  gap: 8px;
}