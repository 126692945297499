.btn_action.btn_payable {
  justify-content: flex-start;
}

@media screen and (max-width: 819px) {
  .account-bottom.payable {
    flex-direction: column-reverse;
  }

  .account-bottom.payable .total_box {
    justify-content: flex-start;
  }
}
