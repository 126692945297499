.date_picker {
  position: relative;
  cursor: pointer;
  padding: 0px;
  height: 30px;
  /* border: 1px solid #d1d7e0; */
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.date_picker .icon {
  transform: translateY(-50%);
  top: 50%;
  right: 8px;
  position: absolute;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.date_picker input.flatpickr-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  padding: 6px 0px;
  z-index: 5;
}
