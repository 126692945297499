.OrderPage_search {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.OrderPage.orders .select_dropdown,
.orders.OrderPage .delivery_date input {
  width: 180px;
}

.orders.OrderPage .OrderPage_search {
  margin-bottom: 0;
}

.orders.OrderPage #content {
  display: none;
}

.OrderPage .Orderpage_filter {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  column-gap: 32px;
}
.Orderpages_filters{
  margin-left: 240px;
}

.OrderPage_search_box {
  border: 1px solid #000;
  border-radius: 50px;
  width: 300px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 4px;
}

.OrderPage .select_search {
  color: #262b40;
  background-color: #fff;
}

.OrderPage_search input {
  flex: 1;
  border: none;
  height: 100%;
  width: 100%;
}

.OrderPage_search_box div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin-right: 5px;
  cursor: pointer;
}

.Orderpage_info .Orderpage_filter .info {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 0;
}

.orders.OrderPage .info_user {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}

.OrderPage_btn {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: center;
}

.OrderPage_table {
  max-width: 100%;
}

.OrderPage_table .marking_input {
  padding: 0;
}

.OrderPage_table .marking_input input {
  padding: 8px;
  height: 48px;
  border: none;
}

.OrderPage .OrderPage_search_box input {
  border: none;
}

.MuiBox-root .MuiTabs-root {
  min-height: auto;
}

.MuiTabs-root .MuiButtonBase-root {
  padding-top: 0;
  min-height: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 32px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.OrderPage_table .order_quantity {
  text-align: end;
}

@media screen and (max-width: 767px) {
  .orders.OrderPage .OrderPage_search_box {
    max-width: 100%;
  }

  .orders.OrderPage .Orderpage_info .info {
    gap: 42px;
  }

  .Orderpage_info .info span {
    min-width: 115px;
    display: block;
  }

  .Orderpage_filter {
    flex-direction: column;
  }

  /* .orders.OrderPage .Orderpage_info .info select,
  .orders.OrderPage .Orderpage_info .info input {
    flex: 1;
  } */
}
